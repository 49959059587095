import "./scss/index.scss";

import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

import { Button, Loader, ProductsFeatured } from "../../components";
import { generateCategoryUrl } from "../../core/utils";

import {
  ProductsList_categories,
  ProductsList_shop,
  ProductsList_shop_homepageCollection_backgroundImage,
} from "./types/ProductsList";

import { structuredData } from "../../core/SEO/Homepage/structuredData";

import noPhotoImg from "../../images/no-photo.svg";

const Page: React.FC<{
  loading: boolean;
  categories: ProductsList_categories;
  backgroundImage: ProductsList_shop_homepageCollection_backgroundImage;
  shop: ProductsList_shop;
}> = ({ loading, categories, backgroundImage, shop }) => {
  const categoriesExist = () => {
    return categories && categories.edges && categories.edges.length > 0;
  };

  return (
    <>
      <script className="structured-data-list" type="application/ld+json">
        {structuredData(shop)}
      </script>
      <div className="home-page__hero">
        <video className="home-page__hero__video" autoPlay muted loop poster="https://inm.app/media/storefront/home/intro-video-thumb.jpg">
          <source src="https://inm.app/media/storefront/home/intro-video.mp4" type="video/mp4" /> 
        </video>
        <div className="home-page__hero-text">
          <div>
            <span className="home-page__hero__title">
              <h1>Authorized Dealer For Major Manufacturers</h1>
            </span>
          </div>
          <div>
            <span className="home-page__hero__title">
              <h1>Also Available Open Box: 20-40% Savings</h1>
            </span>
          </div>
        </div>
        <div className="home-page__hero-action">
          {loading && !categories ? (
            <Loader />
          ) : (
            categoriesExist() && (
              <a href="#shop-by-category">
                <Button>View products</Button>
              </a>
            )
          )}
        </div>
      </div>
      <div className="container">
        <div className="article-page__container">
          <div className="article-page__navigation">
            <img title="Our Phoenix Showroom" src="https://www.inm.app/media/storefront/home/phoenix-aerial-ne-wide.jpg" alt="Our showroom is located in Phoenix, AZ." width="100%" />
          </div>
          <div className="article-page__content">
            <h3>Your Friendly Neighborhood Appliance Store</h3>
            <p>
              We are a family-owned outlet center for major manufacturers such as GE, Whirlpool, LG, and Frigidaire. We sell mis-ordered, "wrong color", "didn't fit", "didn't want", closeout, and scratch and dent appliances with full manufacturer warrantees. We also sell brand new and reconditioned appliances. With thousands of appliances in stock, chances are we have what you need!
            </p>
          </div>
          <div className="article-page__navigation">
            <br />
            <img title="Our Surprise Showroom" src="https://www.inm.app/media/storefront/home/surprise-aerial-ne-wide.jpg" alt="Our showroom is located in Surprise, AZ." width="100%" />
          </div>
          <div className="article-page__content">
            <br />
            <h3>New Surprise Location!</h3>
            <p>
              We are excited to announce that we have opened our second store in Surprise, AZ.  We are offering new and scratch and dent appliances at this location with the same personal and helpful service we offer at our Phoenix store.  Located conveniently at: <a href="https://g.page/r/CSy3ZtGu_rmuEAE">12525 W. Bell Rd. Surprise, AZ 85378</a>  Come see us today!
            </p>
          </div>
        </div>
      </div>
      <ProductsFeatured />
      {categoriesExist() && (
        <div className="home-page__categories" id="shop-by-category">
          <div className="container">
            <h3>Shop by category</h3>
            <div className="home-page__categories__list">
              {categories.edges.sort((a, b) => a.node.description > b.node.description ? 1:-1).map(({ node: category }) => (
                <div key={category.id}>
                  <Link
                    to={generateCategoryUrl(category.id, category.name)}
                    key={category.id}
                  >
                    <div
                      className={classNames(
                        "home-page__categories__list__image",
                        {
                          "home-page__categories__list__image--no-photo": !category.backgroundImage,
                        }
                      )}
                      style={{
                        backgroundImage: `url(${
                          category.backgroundImage
                            ? category.backgroundImage.url
                            : noPhotoImg
                        })`,
                      }}
                    />
                    <h3>{category.name}</h3>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Page;
