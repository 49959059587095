import { media, styled } from "@styles";
import { css } from "styled-components";

const textProps = css`
  font-size: ${props => props.theme.typography.baseFontSize};
  margin: 0 0 0.5rem 0;
  text-align: left;
`;

export const Wrapper = styled.div`
  background: ${props => props.theme.colors.light};
  padding: 2.5rem;
  text-align: center;
  max-height: 30rem;
  transition: 0.3s;

  :hover {
    background-color: ${props => props.theme.colors.hoverLightBackground};
  }

  ${media.mediumScreen`
    padding: 1.8rem;
  `}
`;

export const Title = styled.h4`
  text-transform: uppercase;
  font-weight: bold;
  user-select: none;
  ${textProps}
`;

export const Condition = styled.p`
  color: ${props => props.theme.colors.lightFont};
  text-transform: capitalize;
  ${textProps}
`;

export const Price = styled.p`
  font-weight: bold;
  color: ${props => props.theme.colors.secondary};
  ${textProps}
`;

export const Image = styled.div`
  width: auto;
  height: auto;
  max-width: 100%;

  > img {
    width: auto;
    height: auto;
    max-width: 100%;
    border: 1px solid ${props => props.theme.colors.secondary};
  }
`;
