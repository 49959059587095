import { generatePageUrl } from "./utils";

export const BASE_URL = "/";
export const PRODUCTS_PER_PAGE = 30;
export const SUPPORT_EMAIL = "support@example.com";
export const PROVIDERS = {
  BRAINTREE: {
    label: "Braintree",
  },
  DUMMY: {
    label: "Pay via Phone",
  },
  STRIPE: {
    href: "https://js.stripe.com/v3/",
    label: "Stripe",
  },
};
export const STATIC_PAGES = [
  {
    label: "Locations",
    url: generatePageUrl("locations"),
  },
  {
    label: "Financing",
    url: generatePageUrl("financing"),
  },
  {
    label: "Service",
    url: generatePageUrl("service"),
  },
  {
    label: "Our History",
    url: generatePageUrl("our-history"),
  },
  {
    label: "Delivery/Installation",
    url: generatePageUrl("delivery-install"),
  },
  {
    label: "Property Management",
    url: generatePageUrl("property-management"),
  },
];
export const SOCIAL_MEDIA = [
  {
    ariaLabel: "facebook",
    href: "https://www.facebook.com/bbappliances/",
    path: require("../images/facebook-icon.svg"),
  },
  {
    ariaLabel: "instagram",
    href: "https://www.instagram.com/bbappliances/",
    path: require("../images/instagram-icon.svg"),
  },
  {
    ariaLabel: "twitter",
    href: "https://twitter.com/bbappliances/",
    path: require("../images/twitter-icon.svg"),
  },
  {
    ariaLabel: "youtube",
    href: "https://www.youtube.com/channel/UCqmk_DOpx42LXbzmo25SL1g/videos",
    path: require("../images/youtube-icon.svg"),
  },
];
export const META_DEFAULTS = {
  custom: [],
  description:
    "One of the largest independent major home appliance retailers in Arizona.  New, out of box, and reconditioned appliances at very competitive prices.",
  image: `${window.location.origin}${require("../images/logo.svg")}`,
  title: "B & B Appliances",
  type: "website",
  url: window.location.origin,
};
