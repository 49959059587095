import "./scss/index.scss";

import isEqual from "lodash/isEqual";
import * as React from "react";
import ReactSVG from "react-svg";

import { TextField } from "@components/molecules";
import { ProductVariantPicker } from "@components/organisms";
import {
  ProductDetails_product_pricing,
  ProductDetails_product_variants,
  ProductDetails_product_variants_pricing,
} from "@sdk/queries/types/ProductDetails";
import { IProductVariantsAttributesSelectedValues, ITaxedMoney } from "@types";

import { TaxedMoney } from "../../@next/components/containers";
import { CartLine } from "../CartProvider/context";
// import { CartContext, CartLine } from "../CartProvider/context";
// import AddToCart from "./AddToCart";

import locationImg from "../../images/location.svg";
import phoneImg from "../../images/phone.svg";

interface ProductDescriptionProps {
  condition: string;
  productVariants: ProductDetails_product_variants[];
  name: string;
  pricing: ProductDetails_product_pricing;
  addToCart(varinatId: string, quantity?: number): void;
  setVariantId(variantId: string);
}

interface ProductDescriptionState {
  quantity: number;
  variant: string;
  variantStock: number;
  variantPricing: ProductDetails_product_variants_pricing;
  variantPricingRange: {
    min: ITaxedMoney;
    max: ITaxedMoney;
  };
}

class ProductDescription extends React.Component<
  ProductDescriptionProps,
  ProductDescriptionState
> {
  constructor(props: ProductDescriptionProps) {
    super(props);

    this.state = {
      quantity: 1,
      variant: "",
      variantPricing: null,
      variantPricingRange: {
        max: props.pricing.priceRange.stop,
        min: props.pricing.priceRange.start,
      },
      variantStock: null,
    };
  }

  getProductPrice = () => {
    const { variantPricingRange, variantPricing } = this.state;

    const { min, max } = variantPricingRange;
    if (variantPricing) {
      if (isEqual(variantPricing.priceUndiscounted, variantPricing.price)) {
        return <TaxedMoney taxedMoney={variantPricing.price} />;
      } else {
        return (
          <>
            <span className="product-description__undiscounted_price">
              <TaxedMoney taxedMoney={variantPricing.priceUndiscounted} />
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <TaxedMoney taxedMoney={variantPricing.price} />
          </>
        );
      }
    }
    if (isEqual(min, max)) {
      return <TaxedMoney taxedMoney={min} />;
    } else {
      return (
        <>
          <TaxedMoney taxedMoney={min} /> - <TaxedMoney taxedMoney={max} />
        </>
      );
    }
  };

  onVariantPickerChange = (
    _selectedAttributesValues?: IProductVariantsAttributesSelectedValues,
    selectedVariant?: ProductDetails_product_variants
  ) => {
    if (selectedVariant) {
      this.setState({
        variant: selectedVariant.id,
        variantPricing: selectedVariant.pricing,
        variantStock: selectedVariant.stockQuantity,
      });
      this.props.setVariantId(selectedVariant.id);
    } else {
      this.setState({ variant: "", variantPricing: null });
      this.props.setVariantId("");
    }
  };

  handleSubmit = () => {
    this.props.addToCart(this.state.variant, this.state.quantity);
  };

  canAddToCart = (lines: CartLine[]) => {
    const { variant, quantity, variantStock, variantPricingRange } = this.state;
    const { min } = variantPricingRange
    const cartLine = lines.find(({ variantId }) => variantId === variant);
    const syncedQuantityWithCart = cartLine
      ? quantity + cartLine.quantity
      : quantity;
    return quantity > 0 && variant && variantStock >= syncedQuantityWithCart && min.net.amount >= 2;
  };

  renderStockStatusButton() {
    const { stockLocationNames } = this.props.productVariants[0]
    const { stockQuantity } = this.props.productVariants[0]
    if (stockQuantity > 0 && stockLocationNames.includes("Phoenix") || stockLocationNames.includes("Warehouse")) {
      return <div>
              <a href="tel:6028701662"><div className="product-description__call-action"><ReactSVG path={phoneImg} className="product-description__action-icon" /><span className="product-description__action-text">Call Phoenix Location</span></div></a>
              <a href="https://g.page/r/Ce_NnZ21IyTwEAE"><div className="product-description__visit-action"><ReactSVG path={locationImg} className="product-description__action-icon" /><span className="product-description__action-text">Visit Phoenix Location</span></div></a>
             </div>;
    }
    else if (stockQuantity > 0 && stockLocationNames.includes("Surprise")) {
      return <div>
              <a href="tel:6232484535"><div className="product-description__call-action"><ReactSVG path={phoneImg} className="product-description__action-icon" /><span className="product-description__action-text">Call Surprise Location</span></div></a>
              <a href="https://g.page/r/CSy3ZtGu_rmuEAE"><div className="product-description__visit-action"><ReactSVG path={locationImg} className="product-description__action-icon" /><span className="product-description__action-text">Visit Surprise Location</span></div></a>
             </div>;
    }
    else {
      return <span className="product-description__sold">Sold</span>;
    }
  }

  render() {
    const { condition } = this.props;
    const { name } = this.props;
    const { quantity } = this.state;
    const { stockLocationNames } = this.props.productVariants[0]
    const { sku } = this.props.productVariants[0]
    const { warranty } = this.props.productVariants[0]
    const { variantPricingRange } = this.state;
    const { min } = variantPricingRange;

    return (
      <div className="product-description">
        <h1>{name}</h1>
        <h5>Condition: {condition}</h5>
        {min.net.amount > 2
          ? <h2>{this.getProductPrice()}</h2>
          : <div className="product-description__call-for-price">Call For Price</div>
        }
        <h4>SKU: {sku}</h4>
        <h4>Location: <span className="product-description__location-text">{stockLocationNames}</span></h4>
        <div className="product-description__module-border-wrap">
          <div className="product-description__module">
            {warranty}
          </div>
        </div>
        <div className="product-description__variant-picker">
          <ProductVariantPicker
            productVariants={this.props.productVariants}
            onChange={this.onVariantPickerChange}
            selectSidebar={true}
          />
        </div>
        <div className="product-description__quantity-input">
          <TextField
            type="number"
            label="Quantity"
            min="1"
            value={quantity || ""}
            onChange={e =>
              this.setState({ quantity: Math.max(1, Number(e.target.value)) })
            }
          />
        </div>
        {this.renderStockStatusButton()}
        {/* <CartContext.Consumer>
          {({ lines }) => (
            <AddToCart
              onSubmit={this.handleSubmit}
              lines={lines}
              disabled={!this.canAddToCart(lines)}
            />
          )}
        </CartContext.Consumer> */}
      </div>
    );
  }
}

export default ProductDescription;
