import React from "react";

import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";

import * as S from "./styles";
import { IProps } from "./types";

export const ProductTile: React.FC<IProps> = ({ product }: IProps) => {
  const price = product.pricing.priceRange.start;
  const condition = product.condition;

  const getProductPrice = () => {
      if (price.net.amount < 2) {
        return <S.Price>Call For Price</S.Price>;
      }
      else {
        return <S.Price><TaxedMoney taxedMoney={price} /></S.Price>;
      }
    };

  return (
    <S.Wrapper data-cy="product-tile">
      <S.Title>{product.name}</S.Title>
      <S.Condition>{condition}</S.Condition>
      {getProductPrice()}
      <S.Image>
        <Thumbnail source={product} />
      </S.Image>
    </S.Wrapper>
  );
};
